var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "b-w pr" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.name = $event.target.value
            },
          },
        }),
        _c("i", { on: { click: _vm.clear } }, [_vm._v("ㄨ")]),
      ]),
      _c("s-button", {
        attrs: { btnText: "保存" },
        on: { "s-click": _vm.modifyName },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }